import { useCallback, useMemo } from 'react'
import { GraphQLClient } from 'graphql-request'
import { API_ENDPOINT } from '../constants'
import { UserAuthenticationInput, AuthenticatedUser } from '../generated/graphql/types'
import { useAuthenticateUserMutation } from '../generated/graphql/authenticate-user.graphql'
import { MutationErrorResponse } from '../mutations/mutation-error-response'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

type TError = MutationErrorResponse<AuthenticatedUser> | null

export function useAuthenticateUser(): {
  mutate: (input: UserAuthenticationInput) => void
  data: AuthenticatedUser | undefined
  isSuccess: boolean
  isError: boolean
  error: TError
} {
  const result = useAuthenticateUserMutation<TError>(new GraphQLClient(API_ENDPOINT))
  const { mutate, data, isSuccess, isError, error } = result

  const doMutation = useCallback(
    function doMutation(input: UserAuthenticationInput) {
      return mutate({ input })
    },
    [mutate]
  )

  return useMemo(() => {
    return {
      mutate: doMutation,
      data: data?.authenticateUser as AuthenticatedUser,
      isSuccess,
      isError,
      error,
    }
  }, [data?.authenticateUser, doMutation, error, isError, isSuccess])
}
