import { useCallback, useMemo } from 'react'
import { GraphQLClient } from 'graphql-request'
import { API_ENDPOINT } from '../constants'
import { MutationErrorResponse } from '../mutations/mutation-error-response'

// Yes this is confusing (Signup vs SignUp) but the back end names it that way and we want SignUp... on the front end.
// The confusion should only be confied to this file :)-
import { useSignUpUserMutation } from '../generated/graphql/signup-user.graphql'
import { User } from '../generated/graphql/types'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

export function useSignupUser(): {
  mutate: (email: string) => void
  data: User | undefined
  isSuccess: boolean
  isError: boolean
  error: MutationErrorResponse | null
} {
  const result = useSignUpUserMutation<MutationErrorResponse>(new GraphQLClient(API_ENDPOINT))
  const { mutate, data, isSuccess, isError, error } = result

  const doMutation = useCallback(
    function doMutation(email: string) {
      return mutate({ email })
    },
    [mutate]
  )

  return useMemo(() => {
    return {
      mutate: doMutation,
      data: data?.signupUser as User,
      isSuccess,
      isError,
      error,
    }
  }, [data?.signupUser, doMutation, error, isError, isSuccess])
}
