import { SchemaFormSubSection } from '@unreserved-frontend-v2/ui/schema-form/models'
import { text } from '@unreserved-frontend-v2/ui/schema-form/utils'

export const signInSchemaForm: SchemaFormSubSection = {
  key: 'sign-in',
  title: `users:sign-in`,
  numColumns: 4,
  actionLabelKey: 'users:sign-in',
  fields: [
    text('email', `users:email-address`, 'col-span-4', true, 'sign-in-email'),
    { ...text('password', `users:password`, 'col-span-4', true), type: 'password' },
  ],
}
