// NOTE: GA events must have defined triggers & tags in google tag manager
export type AnalyticsEvent =
  | 'sign_up' // GA
  | 'generate_lead' // GA
  | 'generate_property_assessment_lead' // GA
  | 'page_view_property_list' // GA
  | 'page_view_property_details' // GA
  | 'page_view_property_list_map' // GA
  | 'page_view_property_list_grid' // GA
  | 'property_listing_opportunity_inquiry_created' // GA
  | 'roomplanner_open' // GA
  | 'roomplanner_item_placed' // GA
  | 'Lead' // FB
  | 'Schedule' // FB
  | 'CompleteRegistration' // FB
// TODO: event naming consistency and documentation cross-platform

/**
 * Generic function to push custom events to the google tag manager dataLayer
 */
export const pushAnalyticsEvent = (eventName: AnalyticsEvent) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).dataLayer?.push({ event: eventName })
}

// For now FB and GA are inconsistent so we will keep this as a separate function
export const pushFBEvent = (eventName: AnalyticsEvent) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fbq = (window as any).fbq

  if (fbq) {
    fbq('track', eventName)
  }
}
