import { useGetUserLookup } from '@unreserved-frontend-v2/api/hooks/use-get-user-lookup'
import { UserLookup } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { useSignupUser } from '@unreserved-frontend-v2/api/hooks/use-signup-user'
import { SchemaFormLite } from '@unreserved-frontend-v2/ui/schema-form/schema-form-lite'
import { useRedirectTo } from '@unreserved-frontend-v2/ui/hooks/use-redirect-to'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { pushAnalyticsEvent } from '../../../analytics/push-analytics-event'
import { mapMutationErrorsForUI } from '../../../api-mappings/errors'
import { DEFAULT_LISTING_DIRECTORY, DEFAULT_LISTING_SLUG } from '../../../listings/utils/routing/constants'
import { RegisterOrSignInFormData, registerOrSignInSchemaForm } from '../../schema/register-or-sign-in-form'
import SignIn from '../sign-in'
import { RegisterPendingVerfication } from './register-pending-verification'
import { RegisterSuccess } from './register-success'
import { CloseReason } from '@unreserved-frontend-v2/ui/modal/types'

export interface RegisterOrSignInProps {
  onClose?: (reason: CloseReason) => void
  showTitle?: boolean
  fullscreen?: boolean
  className?: string
  successClassname?: string
}

export function RegisterOrSignIn({
  onClose,
  showTitle,
  fullscreen = true,
  className = '',
  successClassname = '',
}: RegisterOrSignInProps) {
  // undefined is only initial state of email so that we don't call lookup without an email at the start
  // otherwise, we want to let the empty string go through to the back-end to get validation
  const [email, setEmail] = useState<string | undefined>(undefined)
  const { redirectTo } = useRedirectTo(`${DEFAULT_LISTING_DIRECTORY}/${DEFAULT_LISTING_SLUG}`)
  const { mutate: signUpUser, isSuccess: isSignUpSuccess, error: signUpError } = useSignupUser()
  const { userLookup, error: lookupError } = useGetUserLookup(email as string, {
    // Make sure the data doesn't get cached because the query will be marked as inactive (not stale) as soon as we
    // redirect off page and we don't want the data to be cached if we come through this page again within 5 mins,
    // which is the default cache time for inactive queries.
    // https://tanstack.com/query/v4/docs/guides/important-defaults#:~:text=By%20default%2C%20%22-,inactive,-%22%20queries%20are%20garbage
    cacheTime: 0,
    enabled: email !== undefined,
  })
  const [showSpinner, setShowSpinner] = useState(false)

  const handleSubmit = useCallback(
    (data: RegisterOrSignInFormData) => {
      setEmail((prevEmail) => {
        setShowSpinner(prevEmail !== data.email)
        return data.email
      })
    },
    [setEmail]
  )

  useEffect(() => {
    if (userLookup === UserLookup.NotFound) {
      signUpUser(email as string)
    }
  }, [userLookup, email, signUpUser])

  useEffect(() => {
    if (isSignUpSuccess) {
      pushAnalyticsEvent('sign_up')
    }
  }, [isSignUpSuccess])

  const errors = useMemo(() => {
    return mapMutationErrorsForUI(lookupError || signUpError || null)
  }, [signUpError, lookupError])

  return (
    <>
      {userLookup !== UserLookup.Found && userLookup !== UserLookup.FoundNotConfirmed && !isSignUpSuccess ? (
        <div className={className} data-testid="register-or-signin-schema-form">
          <SchemaFormLite
            formId="register-or-sign-in"
            onSubmit={handleSubmit}
            section={registerOrSignInSchemaForm}
            errors={errors}
            showTitle={showTitle}
            showLoaderOnSubmit={showSpinner}
          />
        </div>
      ) : null}

      {isSignUpSuccess ? (
        <RegisterSuccess onClose={onClose} redirectTo={redirectTo} className={successClassname} />
      ) : null}

      {userLookup === UserLookup.Found ? (
        <SignIn email={email} className={className} onSuccess={onClose} showTitle={showTitle} />
      ) : null}

      {userLookup === UserLookup.FoundNotConfirmed ? (
        <RegisterPendingVerfication
          onClose={onClose}
          redirectTo={redirectTo}
          email={email as string}
          className={className}
          successClassname={successClassname}
          fullscreen={fullscreen}
        />
      ) : null}
    </>
  )
}

export default RegisterOrSignIn
