import { useCallback, useMemo } from 'react'
import { GraphQLClient } from 'graphql-request'
import { API_ENDPOINT } from '../constants'
import {
  useResendSignupEmailMutation,
  ResendSignupEmailMutation,
} from '../generated/graphql/resent-sign-up-email.graphql'
import { MutationErrorResponse } from '../mutations/mutation-error-response'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

type TError = MutationErrorResponse<ResendSignupEmailMutation>

export function useResendSignUpEmail() {
  const result = useResendSignupEmailMutation<TError>(new GraphQLClient(API_ENDPOINT))
  const { mutate, isSuccess, isError, data, error } = result

  const doMutation = useCallback(
    function (email: string) {
      mutate({ email })
    },
    [mutate]
  )

  return useMemo(() => {
    return { mutate: doMutation, data: data?.resendSignupEmail, isError, isSuccess, error }
  }, [data?.resendSignupEmail, doMutation, error, isError, isSuccess])
}
