import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import Link from 'next/link'
import { SchemaFormLite } from '@unreserved-frontend-v2/ui/schema-form/schema-form-lite'
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col'
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row'
import { useRedirectTo } from '@unreserved-frontend-v2/ui/hooks/use-redirect-to'
import { signInSchemaForm } from '../schema/sign-in-form'
import { useAuthenticateUser } from '@unreserved-frontend-v2/api/hooks/use-authenticate-user'
import { UserAuthenticationInput } from '@unreserved-frontend-v2/api/generated/graphql/types'
import useTranslation from 'next-translate/useTranslation'
import { DEFAULT_LISTING_DIRECTORY, DEFAULT_LISTING_SLUG } from '../../listings/utils/routing/constants'
import { useRouter } from 'next/router'
import { UserContext } from './user-info-provider/user-context'
import { mapMutationErrorsForUI } from '../../api-mappings/errors'
import { getLinkRef } from '@unreserved-frontend-v2/utils/url'
import Footer from './legal-footer/footer'
import { CloseReason } from '@unreserved-frontend-v2/ui/modal/types'

export interface SignInProps {
  className?: string
  showTitle?: boolean
  /** Function to call on successful login. If no function is provided, user will be redirected on login */
  onSuccess?: (reason: CloseReason) => void
  /** Email to preset in login form */
  email?: string
  /** Optionally override the default url to be redirected to on sign in - redirectTo will still take precedence */
  defaultRedirectOnSignIn?: string
  /** Hide the regiser link footer - this is for admin since it doesn't make sense */
  hideSignUp?: boolean
}

export function SignIn({
  className,
  showTitle = true,
  onSuccess,
  email,
  defaultRedirectOnSignIn,
  hideSignUp,
}: SignInProps) {
  const { t } = useTranslation()
  const { push, replace } = useRouter()
  const { updateUser } = useContext(UserContext)
  const { mutate: authenticateUser, data: user, isSuccess, error: mutationError } = useAuthenticateUser()
  const { redirectTo } = useRedirectTo(
    defaultRedirectOnSignIn || `${DEFAULT_LISTING_DIRECTORY}/${DEFAULT_LISTING_SLUG}`
  )

  const errors = useMemo(() => {
    return mapMutationErrorsForUI(mutationError)
  }, [mutationError])

  const onSubmit = useCallback(
    (formData: UserAuthenticationInput) => {
      authenticateUser(formData)
    },
    [authenticateUser]
  )

  useEffect(() => {
    if (user) {
      updateUser(user)
      if (onSuccess) {
        onSuccess(CloseReason.Success)
      } else {
        replace(redirectTo)
      }
    }
  }, [isSuccess, user, onSuccess, redirectTo, push, updateUser, replace])

  return (
    <div className={className}>
      <SchemaFormLite
        formId="sign-in"
        onSubmit={onSubmit}
        section={signInSchemaForm}
        errors={errors}
        showErrors={true}
        showTitle={showTitle}
        className="pb-4"
        // If email does not exist, we need to send undefined, otherwise email input value gets
        // overriden by empty string upon re-render
        values={email ? { email } : undefined}
        fieldToFocus={email ? 'password' : ''}
      />
      <FlexCol className="items-center">
        <Link
          href={getLinkRef('forgot-password', { email, redirectTo })}
          className="text-primary font-bold leading-[30px]"
        >
          {t('users:forgot-password-with-question-mark')}
        </Link>
        {!hideSignUp ? (
          <>
            <FlexRow className="w-full justify-center space-x-1 pb-3 leading-[30px]">
              <span>{t('users:dont-have-account')}</span>
              <Link href={getLinkRef('register-or-sign-in', { redirectTo })} className="text-primary font-bold">
                {t('users:sign-up')}
              </Link>
            </FlexRow>
            <Footer prefixCopy={t('users:by-signing-in')} />
          </>
        ) : null}
      </FlexCol>
    </div>
  )
}

export default SignIn
