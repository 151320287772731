import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

interface FooterProps {
  className?: string
  prefixCopy: string
}

export default function Footer({ className, prefixCopy }: FooterProps) {
  const { t } = useTranslation()

  return (
    <div className={twMerge('text-shades-350 text-center text-sm leading-[26px]', className)}>
      <span>{prefixCopy}</span>
      <Link href="/legal/terms-of-use" target="_blank" className="underline">
        {t('users:terms-of-use')}
      </Link>
      <span> & </span>
      <Link href="/legal/privacy-policy" target="_blank" className="underline">
        {t('users:privacy-policy')}
      </Link>
    </div>
  )
}
