import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { CircleCheckmarkIcon } from '@unreserved-frontend-v2/ui/icons/circle-checkmark'
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col'
import useTranslation from 'next-translate/useTranslation'
import { twMerge } from 'tailwind-merge'
import { useCallback } from 'react'
import { CloseReason } from '@unreserved-frontend-v2/ui/modal/types'

export interface RegisterSuccessProps {
  onClose?: (reason: CloseReason) => void
  redirectTo: string
  className?: string
}

export const RegisterSuccess = ({ onClose, redirectTo, className = '' }: RegisterSuccessProps) => {
  const { t } = useTranslation()

  const handleVerifyClick = useCallback(() => {
    onClose?.(CloseReason.Success)
  }, [onClose])

  return (
    <FlexCol className={twMerge('w-full items-center', className)}>
      <div className="text-primary px-3 pb-5">
        <CircleCheckmarkIcon />
      </div>
      <p className={twMerge('text-center leading-[26px]', onClose ? 'px-11' : '')}>
        {t('users:success-with-exclamation')}
        <br />
        {t('users:please-follow-verification')}
      </p>
      {onClose ? (
        <button
          data-testid="veify-email-close"
          onClick={handleVerifyClick}
          className="border-shades-200 text-primary mt-8 w-full cursor-pointer border-t py-3 text-sm font-bold uppercase tracking-widest"
        >
          {t('ui:close')}
        </button>
      ) : (
        <Button data-testid="veify-email-close" className="mt-7 w-full" href={redirectTo}>
          {t('users:go-back')}
        </Button>
      )}
    </FlexCol>
  )
}
