import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { sanitizeUrl } from '@unreserved-frontend-v2/utils/url'

export function useRedirectTo(defaultRedirect: string) {
  const { query } = useRouter()

  const results = useMemo(() => {
    const { redirectTo, ...otherParams } = query
    let url = decodeURIComponent((redirectTo as string) || `/${defaultRedirect}`)
    if (Object.keys(otherParams).length) {
      url += `?${new URLSearchParams(otherParams as Record<string, string>).toString()}`
    }

    const ignoredPrefix = ['http://', 'https://'].find((key) => url.startsWith(key))
    if (ignoredPrefix) {
      return { redirectTo: `${ignoredPrefix}${sanitizeUrl(url.slice(ignoredPrefix.length))}` }
    }

    return { redirectTo: sanitizeUrl(url) }
  }, [query, defaultRedirect])

  return results
}
