import { SchemaFormSubSection } from '@unreserved-frontend-v2/ui/schema-form/models'
import { text } from '@unreserved-frontend-v2/ui/schema-form/utils'

export const registerOrSignInSchemaForm: SchemaFormSubSection = {
  key: 'register-or-sign-in',
  title: `users:register-or-sign-in`,
  numColumns: 4,
  className: '',
  actionLabelKey: 'users:continue-with-email',
  fields: [text('email', `users:email-address`, 'col-span-4', true, 'register-or-signin-email')],
}

export interface RegisterOrSignInFormData {
  email: string
}
