import { useResendSignUpEmail } from '@unreserved-frontend-v2/api/hooks/use-resend-sign-up-email'
import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { Centered } from '@unreserved-frontend-v2/ui/centered/centered'
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col'
import { CloseReason } from '@unreserved-frontend-v2/ui/modal/types'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { RegisterSuccess } from './register-success'

export interface RegisterPendingVerficationProps {
  email: string
  onClose?: (reason: CloseReason) => void
  redirectTo: string
  className?: string
  successClassname?: string
  fullscreen?: boolean
}

export const RegisterPendingVerfication = ({
  email,
  onClose,
  redirectTo,
  className,
  successClassname = '',
  fullscreen = false,
}: RegisterPendingVerficationProps) => {
  const { t } = useTranslation()
  const { mutate, isSuccess } = useResendSignUpEmail()
  const [isResent, setIsResent] = useState(false)

  const resendEmail = () => {
    mutate(email)
  }

  useEffect(() => {
    if (isSuccess) {
      setIsResent(true)
    }
  }, [isSuccess])

  return !isResent ? (
    <Centered className="bg-shades-25" fullscreen={fullscreen}>
      <FlexCol className={twMerge('items-center', className, 'pt-1 md:pt-0')}>
        <h4 className="text-shades-600 pt-8 pb-5 text-lg font-bold">{t('users:register-or-sign-in')}</h4>
        <p className="px-6 text-center leading-[26px]">{t('users:pending-verfication-copy')}</p>
        <Button className="mt-6 w-full" onClick={resendEmail}>
          {t('users:resend-email')}
        </Button>
      </FlexCol>
    </Centered>
  ) : (
    <RegisterSuccess onClose={onClose} redirectTo={redirectTo} className={successClassname} />
  )
}
