export const enum CloseReason {
  // These are the MUI reasons that are sent:
  // https://mui.com/material-ui/api/dialog/#Dialog-prop-onClose
  EscapeKeyDown = 'escapeKeyDown',
  BackdropClick = 'backdropClick',

  // These are our own reasons. Note that "Success" in this case refers to any action that successfully closed
  // the modal/dialog (e.g. - OK, CONTINUE, CONFIRM, etc...).
  CloseIconClick = 'closeIconClick',
  Success = 'success',
}
